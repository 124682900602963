<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <h1 class="font-bold text-center text-2xl mb-5">Login</h1>  
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-600 pb-1 block">Login</label>
          <input v-model="form.login" type="text" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          <label class="font-semibold text-sm text-gray-600 pb-1 block">Senha</label>
          <input v-model="form.password" type="password" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          <button @click="login" type="button" class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
              <span class="inline-block mr-2">Login</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
          </button>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Login',
  data: function () {
    return {
      form: {
        login: '',
        password: '',
        app: true,
      },
    }
  },
  methods: {
    async login() {
      const resp = await this.$http.post('/login', this.form);

      if (!resp.data.success) {
        if (resp.data.telaExpira) {
          this.$router.push({ path:'/expirou' });
        } else {
          this.$vToastify.error(resp.data.message, 'Erro');
        }
      } else {
        this.err = false;

        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        this.$store.commit('login', resp.data.user);
        this.$router.push({ path:'/home' });
      }
    }
  },
  async beforeMount() {
    const token = this.$route.query.token;

    if (token) {
      const user = await axios.get(`/v1/usuario/me?token=${token}`);

      if (user.data.err && user.data.err.name === 'TokenExpiredError') {
        this.$router.push({ path:'/expirou' });
      }

      if (user.data.success) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user.data.data));
        this.$store.commit('login', user.data.data);
        this.$router.push({ path:'/home' });
      }
    }
  },
}
</script>
