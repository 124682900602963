
<script>
import { Bar, mixins } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';
import chartJsPluginDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins;

export default {
  name: 'stackedBar',
  extends: Bar,
  props:['options', 'chartData'],
  mixins: [ reactiveProp ],
  mounted() {
    this.addPlugin(annotationPlugin);
    this.addPlugin(chartJsPluginDataLabels);
    this.renderChart(
      this.chartData,
      this.options
    )
  },
  watch: {
    chartData: function() {
      this.renderChart(this.chartData, this.options);
    },
    options: function() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>
