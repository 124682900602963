<template>
  <div class="min-h-screen bg-white-100 flex flex-col sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <img class="h-50 w-50" src="@/assets/logo2.jpg" alt="">
      <br>
      <br>
      <p class="text-lg text-center">
        Seu período de acesso expirou. 
        Esperamos que o sistema <b>Dieta Online</b> tenha te auxiliado a estudar e melhorar a sua alimentação. 
        Caso queira renovar seu acesso
        <br><a class="text-blue-500 font-bold" href="https://www.clinicadometabolismo.com/renovar">clique aqui</a>
      </p>
    </div>
  
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Login',
  data: function () {
    return {
      form: {
        login: '',
        password: '',
        app: true,
      },
    }
  },
  methods: {
    async login() {
      const resp = await this.$http.post('/login', this.form);

      if (!resp.data.success) {
        this.$vToastify.error(resp.data.message, 'Erro');
      } else {
        this.err = false;

        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        this.$store.commit('login', resp.data.user);
        this.$router.push({ path:'/home' });
      }
    }
  },
  async beforeMount() {
    const token = this.$route.query.token;

    if (token) {
      const user = await axios.get(`/v1/usuario/me?token=${token}`);

      if (user.data.err && user.data.err.name === 'TokenExpiredError') {
        this.$router.push({ path:'/expirou' });
      }

      if (user.data.success) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user.data.data));
        this.$store.commit('login', user.data.data);
        this.$router.push({ path:'/home' });
      }
    }
  },
}
</script>
