import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/styles/index.css'
import router from './router'
import store from './store';

import VueTailwind from 'vue-tailwind'
import {
  TModal,
  TButton
} from 'vue-tailwind/dist/components';
Vue.use(VueTailwind, {TModal, TButton})


import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import VueToastify from "vue-toastify";
Vue.use(VueToastify, { position: 'top-center'});

import http from './services/http';
Vue.prototype.$http = http;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
