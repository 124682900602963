import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Expirou from '../views/Expirou.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/expirou',
    name: 'Expirou',
    component: Expirou
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresLogin: true }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem('token');
  let objUser = false;

  try {
    objUser = JSON.parse(localStorage.getItem('user'))
  } catch (error) {
    localStorage.removeItem('token');
  }

  if (hasToken && !store.state.isLogged && objUser) {
    store.commit('login', objUser);
    next();
  } else if (to.meta.requiresLogin && !store.state.isLogged) {
    next({name: 'Login'});
  } else if (to.name === 'Login' && hasToken && store.state.isLogged) {
    next(from);
  } else {
    next();
  }
});

export default router
